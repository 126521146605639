interface RadioGroupProp {
  label: string | JSX.Element;
  name: string;
  direction: "flex-row" | "flex-col";
  items: { label: string; icon?: string | JSX.Element; value: string }[];
  spacing?: "dense" | "loose";
  value?: string;
  fullWidth?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const RadioGroup = ({ spacing = "loose", ...props }: RadioGroupProp) => {
  return (
    <div
      className={`flex flex-col ${
        spacing === "loose" ? "gap-2" : ""
      } max-md:text-sm ${props.fullWidth ? "w-full" : "max-w-lg"} `}
    >
      {props.label}
      <div className={`flex ${props.direction} gap-4 flex-wrap`}>
        {props.items.map((i, index) => {
          const isChecked = props.value === i.value;
          return (
            <div
              key={props.name + index}
              className={`form-control px-1 py-[1px] box-border ${
                props.direction === "flex-row" ? "flex-auto" : "flex-1"
              } ${
                isChecked
                  ? "bg-primary text-white border-primary border-2 rounded-md border-opacity-80"
                  : "border-primary border-2 rounded-md border-opacity-50"
              }`}
            >
              <label className="label cursor-pointer gap-4">
                <span
                  className={`label-text text-xs w-full text-center ${
                    isChecked ? "text-white font-bold" : "text-gray-500"
                  }`}
                >
                  {i.label} {i.icon}
                </span>
                <input
                  type="radio"
                  name={`${props.name}:${i.label}:${i.value}`}
                  className={`radio radio-mark radio-xs checked:bg-primary checkbox-primary`}
                  checked={isChecked}
                  onChange={props?.onChange}
                  disabled={props.disabled}
                />
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RadioGroup;
